import './bootstrap';
import '../css/materialize.min.css';
import '../css/styles.css';
import * as Sentry from "@sentry/browser";

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();

Sentry.init({
  dsn: "https://c17e0a408ece2a577f2c560d85e0caba@o4508008641396736.ingest.de.sentry.io/4508008643559504",

  // Amlternatively, use `process.env.npm_package_version` for a dynamic release version
  // if your build tool supports it.
  // release: "my-project-nae@2.3.12",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/app\.agrsoft\.pl\//],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 0.0,
});